import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import { Link } from "gatsby";
import Title from '@lekoarts/gatsby-theme-minimal-blog/src/components/title';
import projects from '../../../../content/projects';
import styles from './bottom.module.css';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Title text="Projects" mdxType="Title" />
    <div>
  {projects.map(project => <>
        {project.to ? <Link className={styles.link} to={project.to} mdxType="Link">{project.name}</Link> : <a className={styles.link} href={project.href} target="_blank" rel="noopener noreferrer">{project.name}</a>}
        <p className={styles.description}>{project.description}</p>
      </>)}
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      